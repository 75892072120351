@import "../../global/scss/global";

.Skills {
    @include sectionSize;


    .CategoryName {
        margin-left: 15px;
        font-style: italic;
        color: $fadedColor;
        font-size: smaller;
    }

    .languageImage {
        max-width: 20px;
        max-height: 20px;
        margin-right: 10px;
    }

    .activeSkill {
        display: block;
    }

    .hiddenSkill {
        display: none;
    }
}