@import "../../global/scss/global";

.Hero {
    @include sectionSize;
    background: linear-gradient(0deg, rgba($accent , 0.7), rgba($accent, 0.3));
    text-align: center;
    position: relative;

    .Overlay {
        background-position: center center;
        background-size: cover;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
    }

    .MainImage {
        border-radius: 50%;
    }
}