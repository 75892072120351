@import '../../global/scss/global';

.Experience {
    @include sectionSize;
    border-top: 2px solid $seconday;
    border-bottom: 2px solid $seconday;

    .Title {
        color: $accent;
        font-style: italic;
    }
}