@import "../../global/scss/global";

.Education {
    @include sectionSize;

    .CardContainer {

        padding: 20px 0;

        .SchoolLogo {
            width: auto;
            margin: 10px auto;
        }

        .SchoolName {
            text-align: center;
        }

        .CardActions {
            display: flex;
            justify-content: space-between;
            padding: 0 30px;

        }
    }


}