@import "../../global/scss/global";

.About {
    @include sectionSize;

    .Img {
        height: 416px;
        object-fit: cover;
        vertical-align: middle;
        display: inline-block;
        max-width: 100%;
        border: none;
        border-radius: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
    }

    .AboutMeSection {
        height: 100%;
        line-height: 2;
    }

    .AboutList {
        list-style: none;
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }

    .Icon {
        margin-right: 30px;
    }

    .Button {
        position: relative;
        display: flex;
        padding: 10px;
        justify-content: center;
        color: white;
        text-decoration: none;
        border-radius: 5px;

        svg {
            position: absolute;
            left: 15px;
        }

        &.HireMe {
            background: $seconday;
        }

        &.LinkedIn {
            background: #0072b1;
        }

        &.GitHub {
            background: #f5f5f5;
            color: $fontColor;
        }

        &.Resume {
            background: $accent;
        }
    }
}