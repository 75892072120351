@import "../../global/scss/global";

.ContactMe {
    @include sectionSize;

    background: linear-gradient(0deg, rgba($fontColor , 0.7), rgba($fontColor, 0.9));
    position: relative;

    .Overlay {
        background-position: center center;
        background-size: cover;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
    }
}